import _ from "lodash";
import React from "react";
import {
    getParams,
    navigateTo,
    removeParams, setParams
} from "../../utils/location";
import AppContainer from "../AppContainer";
import Button from "../Button";
import ButtonGroup from "../ButtonGroup";
import CouponBatches from "../coupon-batches";
import ErrorBlock from "../ErrorBlock";
import ErrorMessage from "../ErrorMessage";
import Loader from "../Loader";
import RechargeCardView from "../RechargeCardView";

import { connect } from "react-redux";
import withTranslation from "../../hocs/withTranslation";

import {
    getCouponBatchesForPurchaseEvent, loadLoyaltyProfile,
    loadPaymentMethods, respondToPurchaseEventFromPOS, setCouponsCheckedForPurchaseEvent
} from "../../store/user/actions";

import { rechargeCard } from "../../store/order/actions";

import { getCurrentPaymentMethodDetails } from "../../store/selectors";

class PurchaseEventView extends React.Component {
  onSubmit = (approve) => () =>
    this.props.respondToPurchaseEventFromPOS({
      approve,
      couponsBatchIndex: this.props.user.couponsBatchIndex,
      purchaseEventId: this.props.user.currentPurchaseEvent.id,
    });

  componentDidMount() {
    const {
      user: { currentPurchaseEvent },
      loadLoyaltyProfile,
      getCouponBatchesForPurchaseEvent,
    } = this.props;

    loadLoyaltyProfile();
    if (!_.isEmpty(currentPurchaseEvent)) {
      getCouponBatchesForPurchaseEvent(currentPurchaseEvent.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      _.isEmpty(this.props.user.currentPurchaseEvent) &&
      !_.isEmpty(nextProps.user.currentPurchaseEvent)
    ) {
      this.props.loadLoyaltyProfile();
    }
    if (
      !nextProps.user.currentPurchaseEvent &&
      getParams(nextProps.location).rechargeCard
    ) {
      navigateTo(removeParams(this.props.location, "rechargeCard"));
    }
    if (
      this.props.user.respondToPurchaseEventFromPOS.sending &&
      nextProps.user.respondToPurchaseEventFromPOS.sent
    ) {
      navigateTo(getParams(this.props.location).from);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.user.rechargeCard.sent &&
      this.props.user.rechargeCard.sent
    ) {
      this.props.loadLoyaltyProfile();
      navigateTo(removeParams(this.props.location, "rechargeCard"));
    }
  }

  goToRechargeCard = () => {
    const paymentTypeIdentifier = _.get(
      this.props.paymentTypeDetails,
      "paymentType",
    );
    this.props.loadPaymentMethods(paymentTypeIdentifier);
    navigateTo(setParams(this.props.location, { rechargeCard: true }));
  };

  render() {
    const {
      T,
      app,
      app: { keyboardOpen },
      user,
      pageContext: {
        business: { currencySymbol, appStyles },
        businessAppConfiguration: { idRequired },
      },
      paymentTypeDetails,
      location,
      getCouponBatchesForPurchaseEvent,
    } = this.props;
    const {
      currentPurchaseEvent,
      respondToPurchaseEventFromPOS: { sending, sent, error },
      loyaltyProfile,
      purchaseEventBatchResponse,
      couponsUncheckedByUser,
      couponsCheckedByUser,
      couponsBatchIndex,
    } = user;
    if (loyaltyProfile.loading || purchaseEventBatchResponse.loading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "calc(50% + 60px)",
          }}
        >
          <Loader appStyles={appStyles} />
          <strong>{T("Loading...")}</strong>
        </div>
      );
    }

    if (loyaltyProfile.error || _.isEmpty(loyaltyProfile.data)) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "calc(50% + 60px)",
          }}
        >
          <ErrorMessage appStyles={appStyles}>
            {T("Something went wrong")}
            <Button
              slim
              centered
              appStyles={appStyles}
              onClick={this.props.loadLoyaltyProfile}
            >
              {T("Try Again")}
            </Button>
          </ErrorMessage>
        </div>
      );
    }

    if (
      purchaseEventBatchResponse.error ||
      _.isEmpty(purchaseEventBatchResponse.data)
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "calc(50% + 60px)",
          }}
        >
          <ErrorMessage appStyles={appStyles}>
            {T("Something went wrong")}
            <Button
              slim
              centered
              appStyles={appStyles}
              onClick={() =>
                currentPurchaseEvent
                  ? getCouponBatchesForPurchaseEvent(currentPurchaseEvent.id)
                  : navigateTo("/")
              }
            >
              {T("Try Again")}
            </Button>
          </ErrorMessage>
        </div>
      );
    }

    if (sending) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "calc(50% + 60px)",
          }}
        >
          <Loader appStyles={appStyles} />
          <strong>{T("Sending your response...")}</strong>
        </div>
      );
    }

    const rechargeCardMode = Boolean(getParams(location).rechargeCard);
    const { PurchaseEvent = {} } = appStyles;
    const amountOnCard =
      loyaltyProfile.data.openChargeCardInstance.loadedAmount -
      loyaltyProfile.data.openChargeCardInstance.usedAmount;

    if (!currentPurchaseEvent) {
      return (
        <div>
          <div
            style={{
              ...PurchaseEvent,
              fontSize: 38,
              marginTop: "calc(50% + 60px)",
            }}
          >
            {T("This payment has been canceled.")}
          </div>
          <AppContainer.Footer
            relativePosition={keyboardOpen}
            appStyles={appStyles}
          >
            <AppContainer.Footer.Button
              onClick={() => navigateTo(getParams(location).from)}
              appStyles={appStyles}
              centered
              style={{ height: 45 }}
            >
              {T("Go back")}
            </AppContainer.Footer.Button>
          </AppContainer.Footer>
        </div>
      );
    }

    const amountToPay =
      _.isEmpty(purchaseEventBatchResponse.data.batches) ||
      couponsBatchIndex === -1
        ? currentPurchaseEvent.amount
        : _.get(
            purchaseEventBatchResponse,
            `data.batches[${couponsBatchIndex}].priceAfterDiscount`,
          );

    if (!_.isNumber(amountToPay)) {
      console.error("Something is wrong with this coupon");
    }

    return rechargeCardMode ? (
      <RechargeCardView
        T={T}
        appStyles={appStyles}
        user={user}
        location={location}
        idRequired={idRequired}
        keyboardOpen={keyboardOpen}
        currencySymbol={currencySymbol}
        paymentTypeDetails={paymentTypeDetails}
        rechargeCard={this.props.rechargeCard}
        loadLoyaltyProfile={loadLoyaltyProfile}
        amountNeeded={amountToPay - amountOnCard}
      />
    ) : (
      <AppContainer.Content appStyles={appStyles}>
        {!_.isEmpty(error) && (
          <ErrorBlock style={{ margin: 60 }} appStyles={appStyles}>
            {error}
          </ErrorBlock>
        )}
        <div style={{ ...PurchaseEvent, fontSize: 38 }}>
          {T("You've Received a Payment for")}
        </div>
        <div
          style={{ ...PurchaseEvent, fontSize: 50 }}
        >{`${currencySymbol} ${amountToPay.toFixed(2)}`}</div>
        {amountOnCard < amountToPay && (
          <div style={{ ...PurchaseEvent }}>
            {T(
              `* You don't have enough money, you must recharge your card in order to pay`,
            )}
          </div>
        )}

        {!_.isEmpty(_.get(purchaseEventBatchResponse, "data.batches")) && (
          <CouponBatches
            appStyles={appStyles}
            currencySymbol={currencySymbol}
            T={T}
            couponsUncheckedByUser={couponsUncheckedByUser}
            couponsCheckedByUser={couponsCheckedByUser}
            couponsBatchIndex={couponsBatchIndex}
            onChange={this.props.setCouponsCheckedForPurchaseEvent}
            {...purchaseEventBatchResponse.data}
            app={app}
          />
        )}

        <AppContainer.Footer
          relativePosition={keyboardOpen}
          appStyles={appStyles}
        >
          <ButtonGroup tiles centered appStyles={appStyles}>
            {amountOnCard < amountToPay ? (
              <Button
                onClick={this.goToRechargeCard}
                appStyles={appStyles}
                centered
                style={{ height: 45 }}
              >
                <span>{T("Recharge")}</span>
              </Button>
            ) : (
              <Button
                onClick={this.onSubmit(true)}
                appStyles={appStyles}
                centered
                style={{ height: 45 }}
              >
                <span>{T("Approve")}</span>
              </Button>
            )}
            <Button
              onClick={this.onSubmit(false)}
              appStyles={appStyles}
              centered
              style={{ height: 45 }}
            >
              {T("Deny")}
            </Button>
          </ButtonGroup>
        </AppContainer.Footer>
      </AppContainer.Content>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { user, app } = state;
  return {
    user,
    app,
    paymentTypeDetails: getCurrentPaymentMethodDetails(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: {
      businessAppConfiguration: { hasCashback, loadCardEnabled },
      business,
    },
  } = props;

  const currency = _.get(business, "openChargeCard.price.currency", 0);
  const chargeCardBasePrice = _.get(business, "openChargeCard.price");

  return {
    respondToPurchaseEventFromPOS: ({
      approve,
      purchaseEventId,
      couponsBatchIndex,
    }) =>
      dispatch(
        respondToPurchaseEventFromPOS({
          approve,
          purchaseEventId,
          couponsBatchIndex,
        }),
      ),
    loadLoyaltyProfile: (force) => dispatch(loadLoyaltyProfile(force)),
    getCouponBatchesForPurchaseEvent: (purchaseEventId) =>
      dispatch(getCouponBatchesForPurchaseEvent(purchaseEventId)),
    setCouponsCheckedForPurchaseEvent: (params) =>
      dispatch(setCouponsCheckedForPurchaseEvent(params)),
    loadPaymentMethods: (paymentTypeIdentifier) =>
      dispatch(loadPaymentMethods(paymentTypeIdentifier, currency)),
    rechargeCard: ({
      customerDetails,
      cvv,
      creditCard,
      chargeMultiple,
      paymentTypeDetails,
    }) =>
      dispatch(
        rechargeCard({
          customerDetails,
          cvv,
          paymentTypeDetails,
          currency,
          creditCard,
          chargeCardBasePrice,
          chargeMultiple,
        }),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation((props) => <PurchaseEventView {...props} />));
