import ceil from "lodash/ceil";
import get from "lodash/get";
import React from "react";
import { getParams, setParams } from "../../utils/location";
import AppContainer from "../AppContainer";
import ErrorMessage from "../ErrorMessage";
import PaymentAPIScriptLoader, {
    shouldLoadScript
} from "../PaymentAPIScriptLoader";
import PaymentMethodInput from "../PaymentMethodInput";
import * as styles from "./index.module.scss";

const PAYMENT_METHOD_INPUT = "PAYMENT_METHOD_INPUT";

export default class extends React.Component {
  state = { shouldLoadPayment: false };

  componentDidMount() {
    const { paymentTypeDetails, user, loadLoyaltyProfile } = this.props;
    if (
      this.isLoyaltyProfileLoaded() &&
      !_.get(user, "loyaltyProfile.loading")
    ) {
      loadLoyaltyProfile(true);
    }
    this.setState({ shouldLoadPayment: shouldLoadScript(paymentTypeDetails) });
  }

  isLoyaltyProfileLoaded = () => {
    const {
      user: { loyaltyProfile },
    } = this.props;
    return loyaltyProfile.data;
  };

  onRechargeCardClick = (chargeMultiple) => () => {
    const { user, rechargeCard, paymentTypeDetails } = this.props;
    if (this.state.shouldLoadPayment && !this.state.paymentLoaded) {
      console.error("not handled, zooz should be loaded but it failed to load");
      return;
    }

    const callback = (err, res) => {
      if (err) {
        console.log(err);
      } else {
        console.log("recharching card...");
        rechargeCard({
          paymentTypeDetails,
          customerDetails: {
            email: user.userDetails.data.email,
            phoneNumber: user.userDetails.data.phoneNumber,
            fullName: user.userDetails.data.name,
          },
          chargeMultiple,
          ...res,
        });
      }
    };
    console.log("Validating payment method");
    this.fieldsRefs[PAYMENT_METHOD_INPUT].validate(callback);
  };

  onInputError = (field) => (error) =>
    this.setState({ [errorkey(field)]: error });

  onInputValid = (field) => (value) => {
    this.setState({ [errorkey(field)]: null, [field]: value });
  };

  registerInput = (field) => (ref) => {
    this.fieldsRefs = { ...this.fieldsRefs, [field]: ref };
  };

  isValidField = (field) => !this.state[errorkey(field)] && !!this.state[field];

  getSingleLoadAmount = () => {
    const { user } = this.props;
    const singleLoadAmount = _.get(
      user,
      "loyaltyProfile.data.openChargeCardInstance.chargeCard.cardAmount.amount",
    );
    return singleLoadAmount;
  };

  getSingleLoadPrice = () => {
    const { user } = this.props;
    const singleLoadPrice = get(
      user,
      "loyaltyProfile.data.openChargeCardInstance.chargeCard.price.amount",
    );
    return singleLoadPrice;
  };

  getChargeMultiple = (singleLoadAmount) => {
    const { amountNeeded } = this.props;

    return amountNeeded && singleLoadAmount > 0
      ? ceil(amountNeeded / singleLoadAmount)
      : 1;
  };

  getInputPropsFor = (inputId, refKey = "refEl") => ({
    [refKey]: this.registerInput(inputId),
    onValid: this.onInputValid(inputId),
    onError: this.onInputError(inputId),
    T,
  });

  render() {
    const {
      T,
      appStyles,
      user,
      location,
      idRequired,
      keyboardOpen,
      currencySymbol,
      classNames,
      paymentTypeDetails,
    } = this.props;

    const singleLoadAmount = this.getSingleLoadAmount();
    const chargeMultiple = this.getChargeMultiple(singleLoadAmount);
    const singleLoadPrice = this.getSingleLoadPrice();

    return (
      <AppContainer.Content appStyles={appStyles} classNames={classNames}>
        {this.state.shouldLoadPayment && (
          <PaymentAPIScriptLoader
            paymentTypeDetails={paymentTypeDetails}
            onLoad={() => this.setState({ paymentLoaded: true })}
          />
        )}
        {user.rechargeCard.error && (
          <ErrorMessage appStyles={appStyles}>
            {T(`${user.rechargeCard.error}`)}
          </ErrorMessage>
        )}
        <div className={styles.Gutters}>
          <PaymentMethodInput
            user={this.props.user}
            appStyles={appStyles}
            T={T}
            getInputPropsFor={this.getInputPropsFor}
            paymentTypeDetails={paymentTypeDetails}
            changeTargetURL={setParams("/payment-methods", {
              backPath: setParams(location.pathname, {
                ...getParams(location),
                rechargeCard: true,
              }),
            })}
            requireCVV
            idRequired={idRequired}
            paymentScriptLoaded={this.state.paymentLoaded}
            ref={this.registerInput(PAYMENT_METHOD_INPUT)}
          />
          <AppContainer.Footer
            relativePosition={keyboardOpen}
            ref={(el) => {
              this.footer = el;
            }}
            appStyles={appStyles}
          >
            <AppContainer.Footer.Button
              onClick={this.onRechargeCardClick(chargeMultiple)}
              appStyles={appStyles}
              loading={user.rechargeCard.sending}
              completed={user.rechargeCard.sent}
              spread
              classNames={styles.Footer}
            >
              <div>{T("Recharge")}</div>
              {this.isLoyaltyProfileLoaded() && (
                <div>
                  {`${T("Charge")} ${
                    chargeMultiple > 1 ? `${chargeMultiple}X ` : ""
                  }${currencySymbol}${singleLoadPrice} ${T(
                    "and get",
                  )} ${currencySymbol}${chargeMultiple * singleLoadAmount}`}
                </div>
              )}
            </AppContainer.Footer.Button>
          </AppContainer.Footer>
        </div>
      </AppContainer.Content>
    );
  }
}
